import DynamicBarChart from "../../components/Charts/DynamicBarChart";

type SEOProps = {
  report: any;
};

export default function SEM({ report }: SEOProps) {
  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEM" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Antal besökare från SEM</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.sem}
          selectedChannel={"Paid Search"}
          selectedMetric="sessions"
          barName="Trafik"
          color="#F19D35"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEM" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Antal Konverteringar från SEM</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.sem}
          selectedChannel={"Paid Search"}
          selectedMetric="conversions"
          barName="Konverteringar"
          color="#F09637"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEM" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Engagemangsgrad från SEM</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.sem}
          selectedChannel={"Paid Search"}
          selectedMetric="engagementRate"
          barName="Engagemangsgrad"
          color="#F3B14F"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEM" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Konverteringsfrekvens från SEM</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.sem}
          selectedChannel={"Paid Search"}
          selectedMetric="conversionRate"
          barName="Konverteringsfrekvens"
          color="#F6C475"
        />
      </div>

      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/google_ads.webp" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Google Ads</h2>
            <p className="text-base text-gray">Antal klick</p>
          </div>
        </div>
        <DynamicBarChart data={report.sem} selectedMetric="advertiserAdClicks" barName="Antal klick" color="#2e8add" />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/google_ads.webp" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Google Ads</h2>
            <p className="text-base text-gray">Kostnad per klick</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.sem}
          selectedMetric="advertiserAdCostPerClick"
          barName="Kostnad per klick"
          color="#2e8add"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/google_ads.webp" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Google Ads</h2>
            <p className="text-base text-gray">Total kostnad</p>
          </div>
        </div>
        <DynamicBarChart data={report.sem} selectedMetric="advertiserAdCost" barName="Total kostnad" color="#2e8add" />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/google_ads.webp" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Google Ads</h2>
            <p className="text-base text-gray">Antal visningar</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.sem}
          selectedMetric="advertiserAdImpressions"
          barName="Antal visningar"
          color="#2e8add"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/google_ads.webp" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Google Ads</h2>
            <p className="text-base text-gray">Klickfrekvens</p>
          </div>
        </div>
        <DynamicBarChart data={report.sem} selectedMetric="advertiserAdCTR" barName="Klickfrekvens" color="#2e8add" />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/google_ads.webp" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Google Ads</h2>
            <p className="text-base text-gray">Kostnad per konvertering</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.sem}
          selectedMetric="advertiserAdCostPerKeyEvent"
          barName="Kostnad per konvertering"
          color="#2e8add"
        />
      </div>
    </div>
  );
}
