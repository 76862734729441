import React from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from "recharts";
import moment from "moment";

type KPIData = {
  [metricName: string]: {
    start: number;
    goal: number;
  };
};

type DynamicBarChartProps = {
  data: any[];
  selectedMetric: string;
  selectedChannel?: string;
  barName: string;
  color: string;
  kpi?: KPIData | null;
  useTotal?: boolean;
};

export default function DynamicBarChart({
  data,
  selectedMetric,
  selectedChannel,
  barName,
  color,
  kpi,
  useTotal,
}: DynamicBarChartProps) {
  const chartData = React.useMemo(() => {
    return data.map((monthItem: any) => {
      if (selectedChannel) {
        const channelData = monthItem.channel[selectedChannel];
        let rawValue = channelData ? channelData[selectedMetric] : 0;

        // Multiply by 100 if it's a % metric
        if (selectedMetric === "engagementRate" || selectedMetric === "conversionRate") {
          rawValue = Number(rawValue) * 100;
        }
        return {
          yearMonth: monthItem.yearMonth,
          value: rawValue,
        };
      } else if (useTotal) {
        // Access the metric using .total
        let rawValue = monthItem.total[selectedMetric] || 0;

        // Multiply by 100 if it's a % metric
        if (selectedMetric === "engagementRate" || selectedMetric === "conversionRate") {
          rawValue = Number(rawValue) * 100;
        }
        return {
          yearMonth: monthItem.yearMonth,
          value: rawValue,
        };
      } else {
        // Access the metric directly (no .total)
        let rawValue = monthItem[selectedMetric] || 0;

        // Convert CTR to percentage, leave averagePosition as-is (or handle differently)
        if (selectedMetric === "organicGoogleSearchClickThroughRate") {
          rawValue = Number(rawValue) * 100; // e.g. 0.027 becomes 2.7
        }

        return {
          yearMonth: monthItem.yearMonth,
          value: rawValue,
        };
      }
    });
  }, [data, selectedMetric]);

  // If we only want a 0-100 domain for CTR, but not for average position:
  const isCTR = selectedMetric === "organicGoogleSearchClickThroughRate" || selectedMetric === "advertiserAdCTR";
  const isPosition = selectedMetric === "organicGoogleSearchAveragePosition";
  const yAxisDomain = isCTR ? [0, 100] : undefined;
  const yAxisFormatter = (val: number) => {
    if (isCTR) return `${val.toFixed(1)}%`;
    if (isPosition) return val.toFixed(1);

    // Round the number if it thousands or more
    if (val >= 1000) return Math.round(val / 1000) + "k";

    return val.toString();
  };

  const isPercentage = selectedMetric === "engagementRate" || selectedMetric === "conversionRate";

  // Compute reference line positions from KPI data if available
  let startLine: number | null = null;
  let goalLine: number | null = null;
  // If we have KPI data for the selected metric, set reference lines
  if (kpi && kpi[selectedMetric]) {
    const { start, goal } = kpi[selectedMetric];

    // If your KPI is in the same scale as chartData, use them directly
    // If your KPI is in "raw" percentages (e.g. 10 => 10%), but the chart multiplies by 100,
    // multiply here as well. For example:
    if (isPercentage) {
      // E.g. if bounceRate goal is 30 (meaning 30%), we chart y=30
      startLine = start; // "start" is already 30 if you store "30" for 30%
      goalLine = goal;
    } else {
      // For non-percentage metrics (e.g. sessions = 7600), just use it directly
      startLine = start;
      goalLine = goal;
    }
  }

  const customTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const displayValue = isCTR ? `${payload[0].value.toFixed(1)}%` : payload[0].value.toFixed(1);
      return (
        <div className="p-2 bg-white border rounded-md shadow">
          {/* If GA4 dimension is '202401', moment needs 'YYYYMM' format */}
          <p className="font-semibold text-gray-700">{moment(label, "YYYYMM").format("MMM YYYY")}</p>
          <p>{`${barName}: ${displayValue}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
          <XAxis
            dataKey="yearMonth"
            // If your dimension is "YYYYMM" (e.g. "202401"), parse it like:
            tickFormatter={(tick) => moment(tick, "YYYYMM").format("MMM")}
            stroke="#64748b"
          />
          <YAxis yAxisId="left" stroke="#64748b" tickFormatter={yAxisFormatter} />
          <Tooltip content={customTooltip} />
          <Legend />
          {/* If we have startLine / goalLine, add references */}
          {startLine !== null && (
            <ReferenceLine yAxisId="left" y={startLine} stroke="#406595" strokeDasharray="4 4" label="Start" />
          )}
          {goalLine !== null && (
            <ReferenceLine yAxisId="left" y={goalLine} stroke="#52976A" strokeDasharray="4 4" label="Mål" />
          )}
          <Bar dataKey="value" yAxisId="left" name={barName} fill={color} radius={[15, 15, 0, 0]} barSize={8} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
