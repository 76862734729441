import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { Avatar, Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tab, Tabs } from "@nextui-org/react";
import { ArrowUturnLeftIcon, EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import { Twelvepoints } from "../../components/Twelvepoints";
import User from "../../components/User";
import { Customer, Customerservice, client } from "../../utils/client";
import ServiceAvatar from "../../components/ServiceAvatar";
import KeywordTable from "./KeywordTable";
import { useModal } from "../../hooks/useModal";
import ServiceForm from "./ServiceForm";
import BudgetChart from "./sem/BudgetChart";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function Service() {
  const { id } = useParams();
  const { services, customers } = useContext(DataContext);
  const [service, setService] = useState<Customerservice | null>(null);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const navigate = useNavigate();
  const { showModal } = useModal();

  useEffect(() => {
    const fetchServiceAndCustomer = async () => {
      try {
        setLoading(true);
        if (!id) throw new Error("No id provided");
        const foundService = await client.service("services").get(id);
        if (!foundService) {
          throw new Error("Service not found");
        }
        setService(foundService);

        const foundCustomer = await client.service("customers").get(foundService.customerId.toString());

        if (!foundCustomer) {
          throw new Error("Customer not found");
        }

        setCustomer(foundCustomer);
      } catch (err: any) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchServiceAndCustomer();
  }, [services, customers, id]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="relative grid grid-cols-3 gap-5">
      <div className="flex justify-between col-span-3 p-8 panel ">
        <div className="flex space-x-5">
          <Avatar
            name={customer?.name}
            classNames={{
              base: "bg-transparent",
            }}
            src={customer?.favicon || ""}
            size="lg"
          />
          <div>
            <h1 className="my-auto text-2xl font-medium text-gray">{customer!.name}</h1>
            <div className="text-foreground-400">{customer!.website}</div>
            <Button
              onClick={() => navigate("/customers/" + customer!._id.toString())}
              className="mt-2"
              color="default"
              startContent={<ArrowUturnLeftIcon className="w-3 h-3" />}
              size="sm"
            >
              Tillbaka till kund
            </Button>
          </div>
        </div>
        <div className="flex my-auto gap-x-10">
          <User _id={customer?.user?.toString()} size="md" />
          <Twelvepoints customer={customer!} size="lg" />
        </div>
      </div>
      <div className="absolute top-0 right-0 ">
        <Dropdown>
          <DropdownTrigger>
            <EllipsisHorizontalIcon className="w-6 h-6 mt-3 mr-4 cursor-pointer text-foreground-400 hover:text-gray" />
          </DropdownTrigger>
          <DropdownMenu>
            <DropdownItem
              onClick={() => showModal(<ServiceForm service={service!} />, "Redigera tjänst")}
              key="delete"
              color="default"
            >
              Redigera tjänst
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <div className="flex col-span-3 gap-5 lg:col-span-3">
        <div className="w-full p-4 panel">
          <ServiceAvatar service={service!} size="lg" />
          <div className="p-3">
            <h1 className="text-base text-gray">Beskrivning</h1>
            <div className="text-sm text-gray">{service?.description}</div>
          </div>
        </div>
        {service && service.type === "ads" && <BudgetChart service={service!} />}
      </div>
      <div className="flex flex-col col-span-3 gap-5">
        {service && service.type === "seo" && <KeywordTable service={service!} />}
      </div>
    </div>
  );
}
