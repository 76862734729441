import { createContext, useEffect, useState } from "react";
import { client } from "../utils/client";
import { User, Customer, Customerservice, Project, Keyword, Text, Task } from "api";
import { useAuth } from "./AuthContext";
import LoadingSpinner from "../components/LoadingSpinner";

interface PaginatedResult<T> {
  data: T[];
  limit: number;
  skip: number;
  total: number;
}

interface DataContextProps {
  users: PaginatedResult<User>;
  customers: PaginatedResult<Customer>;
  services: PaginatedResult<Customerservice>;
  projects: PaginatedResult<Project>;
  keywords: PaginatedResult<Keyword>;
  texts: PaginatedResult<Text>;
  tasks: PaginatedResult<Task>;
}

type ServiceName = "users" | "customers";

export const DataContext = createContext({} as DataContextProps);

export function DataProvider(props: any) {
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<PaginatedResult<User>>({ data: [], limit: 0, skip: 0, total: 0 });
  const [customers, setCustomers] = useState<PaginatedResult<Customer>>({ data: [], limit: 0, skip: 0, total: 0 });
  const [services, setServices] = useState<PaginatedResult<Customerservice>>({ data: [], limit: 0, skip: 0, total: 0 });
  const [projects, setProjects] = useState<PaginatedResult<Project>>({ data: [], limit: 0, skip: 0, total: 0 });
  const [keywords, setKeywords] = useState<PaginatedResult<Keyword>>({ data: [], limit: 0, skip: 0, total: 0 });
  const [texts, setTexts] = useState<PaginatedResult<Text>>({ data: [], limit: 0, skip: 0, total: 0 });
  const [tasks, setTasks] = useState<PaginatedResult<Task>>({ data: [], limit: 0, skip: 0, total: 0 });

  const serviceConfigs = [
    { name: "users", updater: setUsers },
    { name: "customers", updater: setCustomers },
    { name: "services", updater: setServices },
    { name: "projects", updater: setProjects },
    { name: "keywords", updater: setKeywords },
    { name: "texts", updater: setTexts },
    { name: "tasks", updater: setTasks },
  ];

  // Abstract the fetch logic
  const fetchDataForService = async (serviceName: ServiceName, updater: React.Dispatch<any>) => {
    try {
      // @ts-ignore
      const data = await client.service(serviceName).find();
      updater(data);
    } catch (error) {
      console.log("Error fetching data for service: " + serviceName + " - " + error);
    }
  };

  useEffect(() => {
    if (token) {
      const fetchData = async () => {
        try {
          // Fetch all data concurrently
          await Promise.all(
            serviceConfigs.map(({ name, updater }) => fetchDataForService(name as ServiceName, updater))
          );
          // Set loading to false after all data has been fetched
          setLoading(false);
        } catch (error) {
          console.log("Error fetching data: ", error);
        }
      };

      fetchData();

      // Set up event listeners
      serviceConfigs.forEach(({ name, updater }) => {
        const service = client.service(name as ServiceName);
        service.on("created", () => fetchDataForService(name as ServiceName, updater));
        service.on("patched", () => fetchDataForService(name as ServiceName, updater));
        service.on("removed", () => fetchDataForService(name as ServiceName, updater));

        return () => {
          ["created", "patched", "removed"].forEach((event) => {
            service.removeAllListeners(event);
          });
        };
      });
    }
  }, [token]);

  const value = {
    users,
    customers,
    services,
    projects,
    keywords,
    texts,
    tasks,
  } as DataContextProps;

  return <DataContext.Provider value={value}>{loading ? <LoadingSpinner /> : props.children}</DataContext.Provider>;
}
