import { Button, Link, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, User } from "@nextui-org/react";
import React, { useEffect } from "react";
import { client } from "../../utils/client";
import { useParams } from "react-router-dom";

export default function Texts() {
  const { reportId } = useParams();
  const [texts, setTexts] = React.useState<any>(null);

  const fetchTexts = async () => {
    if (!reportId) return;
    try {
      const fetchedTexts = await client.service("reports").getTexts({ reportId: reportId, month: "" });
      setTexts(fetchedTexts);
    } catch (error) {
      console.error("Error fetching texts:", error);
    }
  };

  useEffect(() => {
    fetchTexts();
  }, [reportId]);

  // Function to approve a text
  const approveText = async (textId: string) => {
    await client.service("reports").approveText({ id: textId });
    fetchTexts();
  };

  return (
    <>
      {texts && texts.length > 0 && (
        <Table
          classNames={{
            wrapper: "shadow-none",
          }}
          aria-label="Example static collection table"
        >
          <TableHeader>
            <TableColumn>TEXTER ATT GODKÄNNA</TableColumn>
            <TableColumn>COPYWRITER</TableColumn>
            <TableColumn>LÄNK</TableColumn>
            <TableColumn>GODKÄNN</TableColumn>
          </TableHeader>
          <TableBody>
            {texts?.map((text: any) => (
              <TableRow key={text._id.toString()}>
                <TableCell>
                  <div className="cursor-pointer hover:underline">
                    <div onClick={() => window.open(text.docsLink, "_blank")} className="text-blue-600 ">
                      {text.title}
                    </div>
                    <div className="text-xs text-slate-600">{text.metadescription}</div>
                  </div>
                </TableCell>
                <TableCell>
                  {text.user && (
                    <User
                      className="bg-transparent"
                      avatarProps={{
                        size: "md",
                        radius: "full",
                        src: text.user.profilePicture,
                        classNames: {
                          base: "bg-transparent",
                        },
                      }}
                      description={text.user.email}
                      name={text.user.fullName}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {text.docsLink && (
                    <Link href={text.docsLink} target="_blank">
                      <Button className="bg-transparent hover:bg-foreground-200">
                        <img src="/google_docs.png" className="w-6 h-6" />
                        <div className="text-sm text-gray">Gå till text</div>
                      </Button>
                    </Link>
                  )}
                </TableCell>
                <TableCell>
                  <Button onClick={() => approveText(text._id.toString())} size="sm" color="success">
                    Godkänn
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
