import KpiForm from "../../components/Forms/KpiForm";
import { Customer } from "../../utils/client";

type CustomerKpiProps = {
  customer: Customer;
};

export default function CustomerKpi({ customer }: CustomerKpiProps) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="border-b border-grayLight">
        <h1 className="text-base rounded-lg">SEO</h1>
        <KpiForm customerId={customer._id.toString()} kpiType="seo" />
      </div>
      <div className="border-b border-grayLight">
        <h1 className="text-base rounded-lg">SEM</h1>
        <KpiForm customerId={customer._id.toString()} kpiType="sem" />
      </div>
      <div className="border-b border-grayLight">
        <h1 className="text-base rounded-lg">CRO</h1>
        <KpiForm customerId={customer._id.toString()} kpiType="cro" />
      </div>
    </div>
  );
}
