import { useEffect, useState } from "react";
import PieChart from "../../components/Charts/PieChart";
import CustomerTeam from "../Customers/CustomerTeam";
import Log from "./Log";

type MainProps = {
  report: any;
  month: any;
};

export default function Main({ report, month }: MainProps) {
  const [seoSessions, setSeoSessions] = useState(0);
  const [seoGoal, setSeoGoal] = useState(0);
  const [semSessions, setSemSessions] = useState(0);
  const [semGoal, setSemGoal] = useState(0);
  const [croValue, setCroValue] = useState(0);
  const [croGoal, setCroGoal] = useState(0);
  const [hasSEO, setHasSEO] = useState(false);
  const [hasSEM, setHasSEM] = useState(false);
  const [hasCRO, setHasCRO] = useState(false);

  useEffect(() => {
    const selectedTraffic = report.traffic?.find((t: any) => t.yearMonth === month.format("YYYYMM"));
    setSeoSessions(selectedTraffic?.channel?.["Organic Search"]?.sessions || 0);
    setSeoGoal(report.kpi?.seo?.sessions?.goal || 0);
    setSemSessions(selectedTraffic?.channel?.["Paid Search"]?.sessions || 0);
    setSemGoal(report.kpi?.sem?.sessions?.goal || 0);
    setCroValue(selectedTraffic?.total?.engagementRate ? Math.round(selectedTraffic.total.engagementRate * 100) : 0);
    setCroGoal(report.kpi?.cro?.engagementRate?.goal || 0);
    setHasSEO(report.services.some((s: any) => s.type === "seo"));
    setHasSEM(report.services.some((s: any) => s.type === "ads"));
    setHasCRO(report.services.some((s: any) => s.type === "cro"));
  }, [report, month]);

  return (
    <>
      <div className="grid grid-cols-3 col-span-2 gap-2 auto-rows-min lg:gap-6">
        <div className="flex panel">
          <PieChart
            icon="/analytics.png"
            title="Måluppfyllnad SEO"
            description="Antal besökare SEO"
            label="Testing"
            value={seoSessions}
            goal={hasSEO ? seoGoal : seoSessions}
            color={hasSEO ? "#406595" : "#f4f4f5"}
          />
        </div>
        <div className="flex panel">
          <PieChart
            icon="/analytics.png"
            title="Måluppfyllnad SEM"
            description="Antal besökare SEM"
            label="Testing"
            value={semSessions}
            goal={hasSEM ? semGoal : semSessions}
            color={hasSEM ? "#F19E38" : "#f4f4f5"}
          />
        </div>
        <div className="flex panel">
          <PieChart
            icon="/analytics.png"
            title="Måluppfyllnad CRO"
            description="Engagemangsgrad"
            explanation="Engagemangsgrad visar andelen besökare som aktivt interagerar med din webbplats eller app genom att stanna längre än 10 sekunder, utlösa en händelse eller besöka flera sidor."
            label="Testing"
            value={croValue}
            goal={hasCRO ? croGoal : croValue}
            color={hasCRO ? "#52976A" : "#f4f4f5"}
            percentage={true}
          />
        </div>
        <div className="col-span-3">
          <Log notes={report.notes} />
        </div>
      </div>
      <div className="w-full col-span-1">
        <CustomerTeam team={report.team} />
      </div>
      <div className="w-full grid-cols-2 gap-2 lg:grid col-span-full lg:gap-6"></div>
    </>
  );
}
