import { useCallback, useContext } from "react";
import "moment/locale/sv";
import User from "../components/User";
import { Avatar, Button, Checkbox, Link, User as NextUser } from "@nextui-org/react";
import ServiceAvatar from "../components/ServiceAvatar";
import { DataContext } from "../context/DataContext";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import SurferIcon from "../components/SurferIcon";
import TitleMetaUrl from "../components/TitleMetaUrl";
import TextStatus from "../components/TextStatus";
import { useModal } from "./useModal";
import TextEditForm from "../components/Forms/TextEditForm";
import { useNavigate } from "react-router-dom";
import DateSince from "../components/DateSince";
import TaskForm from "../components/Forms/TaskForm";
import TaskStatus from "../components/TaskStatus";
import formatCurrency from "../utils/formatCurrency";

export const useRenderCell = () => {
  const { users, customers } = useContext(DataContext);

  const { showModal } = useModal();
  const navigate = useNavigate();
  const getUserFromId = (userId: string) => {
    const user = users.data.find((user) => user._id.toString() === userId);
    return user;
  };

  const removeDomainFromUrl = (url: string) => {
    return url.replace(/^.*\/\/[^\/]+/, "");
  };

  return useCallback((value: any, columnKey: string) => {
    const cellValue = value[columnKey];

    switch (columnKey) {
      case "task":
        return (
          <div className="max-w-64">
            <div className="break-words text-gray ">{value.text}</div>
            <div className="text-xs break-words text-foreground-400 ">{value.description}</div>
          </div>
        );
      case "value":
        return (
          <div className="text-foreground-400">
            {formatCurrency({
              value: value.value,
            })}
          </div>
        );
      case "customer":
        return (
          <NextUser
            className="max-w-sm bg-transparent"
            avatarProps={{
              radius: "lg",
              isBordered: !value.customer.active ? true : false,
              name: value.customer.name,
              src: value.customer.favicon,
              classNames: {
                base: "bg-transparent shrink-0",
              },
            }}
            description={value.customer.website}
            name={<div className="text-base text-gray">{value.customer.name}</div>}
          />
        );
      case "activities":
        return (
          <div className="flex">
            <div className="mx-auto text-gray">{value.activities}</div>
          </div>
        );
      case "customerId":
        const customer = customers.data.find((customer) => customer._id.toString() === value.customerId);
        return (
          <NextUser
            className="justify-start bg-transparent max-w-64"
            avatarProps={{
              radius: "lg",
              size: "sm",
              isBordered: false,
              name: customer?.name,
              src: customer?.favicon || "",
              classNames: {
                base: "bg-transparent shrink-0",
              },
            }}
            description={customer?.website}
            name={<div className="text-gray">{customer?.name}</div>}
          />
        );
      case "contact":
        return <NextUser name={value.name} description={value.title} />;
      case "user":
        return <User _id={value.user} size="sm" />;
      case "deadline":
        return value.deadline ? <DateSince size="sm" date={value.deadline} /> : "-";
      case "createdAt":
        return <DateSince size="sm" date={value.createdAt} />;
      case "updatedAt":
        return <DateSince size="sm" date={value.updatedAt} />;
      case "password":
        return "********";
      case "keyword.surfer.link":
        return (
          <>
            {value.text?.surfer?.id && (
              <Link href={"https://app.surferseo.com/drafts/" + value.text.surfer.id} target="_blank">
                <Button className="bg-transparent hover:bg-foreground-200">
                  <SurferIcon />
                  <div className="text-sm text-gray">Gå till text</div>
                </Button>
              </Link>
            )}
            {value.text?.surfer?.link && !value.text?.surfer?.id && (
              <Link href={value.text.surfer.link} target="_blank">
                <Button className="bg-transparent hover:bg-foreground-200">
                  <SurferIcon />
                  <div className="text-sm text-gray">Gå till text</div>
                </Button>
              </Link>
            )}
          </>
        );
      case "keyword.docsLink":
        return (
          <>
            {value.text?.docsLink && (
              <Link href={value.text.docsLink} target="_blank">
                <Button className="bg-transparent hover:bg-foreground-200">
                  <img src="/google_docs.png" className="w-6 h-6" />
                  <div className="text-sm text-gray">Gå till text</div>
                </Button>
              </Link>
            )}
          </>
        );
      case "surfer.link":
        return (
          <>
            {value.surfer?.id && (
              <Link href={"https://app.surferseo.com/drafts/" + value.surfer.id} target="_blank">
                <Button className="bg-transparent hover:bg-foreground-200">
                  <SurferIcon />
                  <div className="text-sm text-gray">Gå till text</div>
                </Button>
              </Link>
            )}
            {value.surfer?.link && !value.surfer?.id && (
              <Link href={value.surfer.link} target="_blank">
                <Button className="bg-transparent hover:bg-foreground-200">
                  <SurferIcon />
                  <div className="text-sm text-gray">Gå till text</div>
                </Button>
              </Link>
            )}
          </>
        );
      case "docsLink":
        return (
          <>
            {value.docsLink && (
              <Link href={value.docsLink} target="_blank">
                <Button className="bg-transparent hover:bg-foreground-200">
                  <img src="/google_docs.png" className="w-6 h-6" />
                  <div className="text-sm text-gray">Gå till text</div>
                </Button>
              </Link>
            )}
          </>
        );
      case "texttitlemetaurl":
        const data = value.texttitlemetaurl ?? value;
        return <>{data && <TitleMetaUrl text={data} />}</>;
      case "text.status":
        return <>{value.text?.status && <TextStatus status={value.text.status} />}</>;
      case "textStatus":
        return <>{value.status && <TextStatus status={value.status} />}</>;
      case "surfer.start":
        return <>{value.text?.surfer?.start && <div>{value.text.surfer.start}</div>}</>;
      case "surfer.best":
        return <>{value.text?.surfer?.best && <div>{value.text.surfer.best}</div>}</>;
      case "surfer.score":
        return <>{value.text?.surfer?.score && <div>{value.text.surfer.score}</div>}</>;
      case "position":
        return (
          <div className="flex">
            <div className="mr-2 text-gray">{value.position}</div>
            <div
              hidden={value.change === 0}
              className={value.change > -1 ? "text-success text-xs my-auto" : "text-danger text-xs my-auto"}
            >
              {value.change}
            </div>
          </div>
        );
      case "keyword":
        return (
          <div className="max-w-xs">
            <div className=" text-gray">{value.keyword}</div>
            <div className="text-xs text-foreground-400">{removeDomainFromUrl(value.url)}</div>
          </div>
        );
      case "keywordActions":
        return (
          <>
            {value.text && (
              <div className="relative flex items-center justify-end gap-2">
                <Button
                  onClick={() => showModal(<TextEditForm text={value.text} />, "Ändra text")}
                  isIconOnly
                  size="sm"
                  variant="light"
                >
                  <EllipsisVerticalIcon className="w-6 h-6 text-default-300" />
                </Button>
              </div>
            )}
          </>
        );

      case "taskStatus":
        return <TaskStatus task={value} />;
      case "taskActions":
        return (
          <>
            {value.text && (
              <div className="relative flex items-center justify-end gap-2">
                <Button
                  onClick={() => showModal(<TaskForm task={value} />, "Ändra aktivitet")}
                  isIconOnly
                  size="sm"
                  variant="light"
                >
                  <EllipsisVerticalIcon className="w-6 h-6 text-default-300" />
                </Button>
              </div>
            )}
          </>
        );
      case "textActions":
        console.log(value);
        return (
          <>
            {value && (
              <div className="relative flex items-center justify-end gap-2">
                <Button
                  onClick={() => showModal(<TextEditForm text={value} />, "Ändra text")}
                  isIconOnly
                  size="sm"
                  variant="light"
                >
                  <EllipsisVerticalIcon className="w-6 h-6 text-default-300" />
                </Button>
              </div>
            )}
          </>
        );

      case "service":
        return <ServiceAvatar service={value} />;
      case "userId":
        if (!value.userId) return;
        return (
          <div className="flex justify-center">
            <Avatar
              className="shrink-0"
              size="sm"
              name={getUserFromId(value.userId)?.fullName}
              src={getUserFromId(value.userId)?.profilePicture}
            />
          </div>
        );
      case "usericon":
        return (
          <Avatar
            className="ml-auto shrink-0"
            size="sm"
            name={getUserFromId(value.userId)?.fullName}
            src={getUserFromId(value.userId)?.profilePicture}
          />
        );
      case "assignedTo":
        return (
          <Avatar
            className="mx-auto shrink-0"
            size="sm"
            name={getUserFromId(value.assignedTo)?.fullName}
            src={getUserFromId(value.assignedTo)?.profilePicture}
          />
        );
      case "createdBy":
        return (
          <Avatar
            className="mx-auto shrink-0"
            size="sm"
            name={getUserFromId(value.createdBy)?.fullName}
            src={getUserFromId(value.createdBy)?.profilePicture}
          />
        );
      default:
        return <div className="text-sm">{cellValue}</div>;
    }
  }, []);
};
