import React, { useEffect } from "react";
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from "recharts";
import moment from "moment";
import { client, Customer } from "../../utils/client";

type TrafficChartProps = {
  customer: Customer;
};

export default function TrafficChart({ customer }: TrafficChartProps) {
  const [traffic, setTraffic] = React.useState<any[]>([]);

  // Build your date range for the last 12 months
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 12);
  startDate.setDate(1);
  const formattedStartDate = startDate.toISOString().split("T")[0];

  const endDate = new Date();
  const formattedEndDate = endDate.toISOString().split("T")[0];

  useEffect(() => {
    const fetchTrafficData = async () => {
      try {
        const response = await client.service("analytics").getMonthlyData({
          gaPropertyId: customer.gaPropertyId,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        });
        setTraffic(response);
        console.log("Traffic data:", response);
      } catch (error) {
        console.error("Error fetching traffic data:", error);
      }
    };
    fetchTrafficData();
  }, [customer]);

  // Transform data into { yearMonth, seo, paid, engagementRate }
  const chartData = React.useMemo(() => {
    return traffic.map((monthItem) => {
      const seoSessions = monthItem.channel["Organic Search"]?.sessions || 0;
      const paidSearchSessions = monthItem.channel["Paid Search"]?.sessions || 0;
      // Round engagement rate to an integer percent
      const engagementRate = Math.round((monthItem.total.engagementRate || 0) * 100);

      return {
        yearMonth: monthItem.yearMonth,
        seo: seoSessions,
        paid: paidSearchSessions,
        engagementRate: engagementRate,
      };
    });
  }, [traffic]);

  // Extract your KPI goals (make sure they exist in customer.kpi)
  // We'll assume they're simple numbers. If they're missing, default to 0.
  const seoGoal = customer?.kpi?.seo?.sessions || 0;
  const semGoal = customer?.kpi?.sem?.sessions || 0;

  // If your CRO engagementRate is 15 for 15%, we use it directly.
  // If it's stored as 0.15, multiply by 100.
  const engagementRateGoal = customer?.kpi?.cro?.engagementRate || 0;

  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
          {/* X Axis: show month labels */}
          <XAxis dataKey="yearMonth" tickFormatter={(tick) => moment(tick, "YYYY-MM").format("MMM")} stroke="#64748b" />

          {/* Left Y Axis: for SEO & Paid sessions */}
          <YAxis yAxisId="left" stroke="#64748b" tick={{ fill: "#64748b" }} />

          {/* Right Y Axis: for engagement rate (%) set to 0-100 */}
          <YAxis domain={[0, 100]} yAxisId="right" orientation="right" stroke="#64748b" tick={{ fill: "#64748b" }} />

          <Tooltip />
          <Legend />

          {/* ReferenceLine for SEO sessions goal on the left axis */}
          {seoGoal.goal > 0 && (
            <ReferenceLine yAxisId="left" y={seoGoal.goal} stroke="#406595" strokeDasharray="4 4" label="SEO" />
          )}

          {/* ReferenceLine for SEM sessions goal on the left axis */}
          {semGoal.goal > 0 && (
            <ReferenceLine yAxisId="left" y={semGoal.goal} stroke="#F19E38" strokeDasharray="4 4" label="SEM" />
          )}

          {/* ReferenceLine for engagementRate on the right axis */}
          {engagementRateGoal.goal > 0 && (
            <ReferenceLine
              yAxisId="right"
              y={engagementRateGoal.goal}
              stroke="#52976A"
              strokeDasharray="4 4"
              label="CRO"
            />
          )}

          {/* Bar for SEO on left axis */}
          <Bar dataKey="seo" yAxisId="left" name="SEO trafik" fill="#406595" radius={[15, 15, 0, 0]} barSize={8} />

          {/* Bar for Paid Search on left axis */}
          <Bar dataKey="paid" yAxisId="left" name="SEM trafik" fill="#F19E38" radius={[15, 15, 0, 0]} barSize={8} />

          {/* "Bar" for engagement rate on right axis, if you prefer a bar approach */}
          <Bar
            dataKey="engagementRate"
            yAxisId="right"
            name="Engagemangsgrad"
            fill="#52976A"
            radius={[15, 15, 0, 0]}
            barSize={8}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
