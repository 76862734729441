import { CustomerData } from "../utils/client";

export const createEmptyCustomer = (): CustomerData => ({
  name: "",
  active: true,
  website: "",
  kpi: {},
  city: "",
  address: "",
  about: "",
  logins: [],
  contacts: [],
  favicon: "",
  user: "",
});
