import { useParams } from "react-router-dom";
import { client } from "../../utils/client";
import { useEffect, useState } from "react";
import { Avatar, Card, CardBody, Tabs, Tab } from "@nextui-org/react";
import CustomerTeam from "../Customers/CustomerTeam";
import PieChart from "../../components/Charts/PieChart";
import MonthSwitcher from "./MonthSwitcher";
import moment from "moment";
import TrafficChart from "../../components/Charts/TrafficChart";
import Main from "./Main";
import SEO from "./SEO";
import SEM from "./SEM";
import CRO from "./CRO";
import Texts from "./Texts";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function Report() {
  const { reportId } = useParams();
  const [report, setReport] = useState<any>(null);
  const [month, setMonth] = useState(moment("2024-12", "YYYY-MM"));

  // Fetch the complete report (for all months) once.
  useEffect(() => {
    if (!reportId) return;
    const fetchReport = async () => {
      try {
        const fetchedReport = await client.service("reports").getReport({
          reportId,
          month: month.format("YYYY-MM"),
        });
        setReport(fetchedReport);
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };
    fetchReport();
  }, [reportId]);

  if (!report) return <LoadingSpinner />;

  // Function to check if report.services[].type includes SEO, SEM, CRO, etc.
  const hasService = (service: string) => !report.services.some((s: any) => s.type === service);

  // Function to check if report has any texts
  const hasTexts = () => report.texts?.length === 0;

  return (
    <div className="flex w-screen h-full p-2 lg:p-8">
      {report && (
        <div className="grid w-full grid-cols-3 gap-2 mx-auto lg:gap-6 max-w-7xl">
          <div className="flex justify-between col-span-3 p-8 panel rounded-xl">
            <div className="flex space-x-5">
              <Avatar
                name={report.name}
                classNames={{
                  base: "bg-transparent",
                  name: "font-medium",
                }}
                src={report.favicon || ""}
                size="lg"
              />
              <div>
                <h1 className="my-auto text-2xl font-medium text-gray">{report.name}</h1>
                <div className="text-foreground-400">{report.website}</div>
              </div>
            </div>
            <MonthSwitcher month={month} setMonth={setMonth} />
          </div>
          <div className="flex flex-col w-full col-span-full">
            <Tabs aria-label="Options" color="primary" classNames={{ tabList: "bg-white" }}>
              <Tab className="grid grid-cols-3 gap-2 lg:gap-6" key="main" title="Affärsrapport">
                <Main month={month} report={report} />
              </Tab>
              <Tab isDisabled={hasService("seo")} key="seo" title="SEO">
                <SEO report={report} />
              </Tab>
              <Tab isDisabled={hasService("ads")} key="sem" title="SEM">
                <SEM report={report} />
              </Tab>
              <Tab isDisabled={hasService("cro")} key="cro" title="CRO">
                <CRO report={report} />
              </Tab>
              <Tab isDisabled={hasTexts()} key="texts" title="Texter att godkänna">
                <Texts />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
}
