import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { client, Customer } from "../../utils/client";
import { useEffect, useState } from "react";

type CustomerReportProps = {
  customer: Customer;
};

type Event = {
  key: string;
  label: string;
};

export default function CustomerReport({ customer }: CustomerReportProps) {
  const [events, setEvents] = useState<Event[]>([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await client.service("analytics").getAvailableEvents({
          gaPropertyId: customer.gaPropertyId,
        });
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
  }, []);

  // Function to generate a reportId uuid
  const generateReportId = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  };

  // Function to update the reportId with a new reportId
  const updateReportId = async () => {
    await client.service("customers").patch(customer._id.toString(), {
      reportId: generateReportId(),
    });
  };

  const updateEvents = async (selectedEvents: any): Promise<void> => {
    // Convert the events to an array of strings from a SET
    const eventsArray = Array.from(selectedEvents).map((event: any) => event.toString());

    await client.service("customers").patch(customer._id.toString(), {
      trackedEvents: eventsArray,
    });
  };

  return (
    <div className="flex flex-col space-y-4">
      <Input
        fullWidth
        name="reportId"
        isReadOnly={true}
        labelPlacement="inside"
        variant="flat"
        type="text"
        label="GA4 Användar-ID att lägga till"
        value="ga4-data-api-service@new-customers-report.iam.gserviceaccount.com"
      />
      <Select
        items={events}
        className="w-auto"
        label="Välj vilka mål som ska mätas"
        placeholder="Välj mål"
        selectionMode="multiple"
        color="default"
        selectedKeys={customer.trackedEvents}
        onSelectionChange={(selectedEvents) => updateEvents(selectedEvents)}
      >
        {events.map((event) => (
          <SelectItem key={event.key} value={event.key}>
            {event.label}
          </SelectItem>
        ))}
      </Select>
      <Input
        fullWidth
        name="reportId"
        isReadOnly={true}
        labelPlacement="inside"
        variant="flat"
        type="text"
        label="Kundens rapport-ID"
        value={customer.reportId || ""}
      />
      <div className="ml-auto space-x-2">
        <Button onClick={() => window.open("/report/" + customer.reportId)} size="md" color="default">
          Gå till rapport
        </Button>
        <Button onClick={updateReportId} size="md" color="primary">
          Generera nytt rapport-ID
        </Button>
      </div>
    </div>
  );
}
