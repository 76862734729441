import DynamicBarChart from "../../components/Charts/DynamicBarChart";

type CROProps = {
  report: any;
};

export default function CRO({ report }: CROProps) {
  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Engagemangsgrad</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.cro}
          selectedMetric="engagementRate"
          barName="Engagemangsgrad"
          color="#52976A"
          useTotal={true}
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Engagerade besökare</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.cro}
          selectedMetric="engagedSessions"
          barName="Engagerade besökare"
          color="#335F42"
          useTotal={true}
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Genomsnittlig tid på sidan</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.cro}
          selectedMetric="timeOnPage"
          barName="Tid på sidan"
          color="#73C390"
          useTotal={true}
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Konverteringar</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.cro}
          selectedMetric="conversions"
          barName="Konverteringar"
          color="#9DD7B6"
          useTotal={true}
        />
      </div>
    </div>
  );
}
