import DynamicBarChart from "../../components/Charts/DynamicBarChart";

type SEOProps = {
  report: any;
};

export default function SEO({ report }: SEOProps) {
  return (
    <div className="grid grid-cols-2 gap-8">
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Antal besökare från SEO</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.seo}
          selectedChannel={"Organic Search"}
          selectedMetric="sessions"
          barName="Trafik"
          color="#406595"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Antal Konverteringar från SEO</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.seo}
          selectedChannel={"Organic Search"}
          selectedMetric="conversions"
          barName="Konverteringar"
          color="#283F5D"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Engagemangsgrad från SEO</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.seo}
          selectedChannel={"Organic Search"}
          selectedMetric="engagementRate"
          barName="Engagemangsgrad"
          color="#608BC1"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/analytics.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Trend</h2>
            <p className="text-base text-gray">Konverteringsfrekvens från SEO</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.traffic}
          kpi={report.kpi.seo}
          selectedChannel={"Organic Search"}
          selectedMetric="conversionRate"
          barName="Konverteringsfrekvens"
          color="#82A5CE"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/search_console.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Search console</h2>
            <p className="text-base text-gray">Genomsnittlig position</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.seo}
          selectedMetric="organicGoogleSearchAveragePosition"
          barName="Genomsnittlig position"
          color="#9fc1f9"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/search_console.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Search console</h2>
            <p className="text-base text-gray">Visningar</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.seo}
          selectedMetric="organicGoogleSearchImpressions"
          barName="Visningar"
          color="#9fc1f9"
        />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/search_console.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Search console</h2>
            <p className="text-base text-gray">Klick</p>
          </div>
        </div>
        <DynamicBarChart data={report.seo} selectedMetric="organicGoogleSearchClicks" barName="Klick" color="#9fc1f9" />
      </div>
      <div className="p-6 panel">
        <div className="flex mb-4 space-x-4">
          <img className="w-6 h-6 my-auto" src="/search_console.png" alt="SEO" />
          <div className="my-auto">
            <h2 className="text-xs text-slate-500">Search console</h2>
            <p className="text-base text-gray">Klickfrekvens</p>
          </div>
        </div>
        <DynamicBarChart
          data={report.seo}
          selectedMetric="organicGoogleSearchClickThroughRate"
          barName="Klickfrekvens"
          color="#9fc1f9"
        />
      </div>
    </div>
  );
}
